import React, { useState, useEffect } from "react";
import { TextField, Button, List, Box, CircularProgress } from "@mui/material";
import axios from "axios";
import MessageBubble from "./MessageBubble";
import AudioStreamer from './AudioStreamer';
import { api_url } from "../../../config/api";
import { Message } from "./Message";
import { getAccessToken } from "../../../utils/auth";

interface ChatProps {
  sessionId: string;
  startingMessage: Message;
}

const Chat: React.FC<ChatProps> = ({ sessionId, startingMessage }: ChatProps) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState(false);

  // Clear messages when sessionId changes
  useEffect(() => {
    setMessages([startingMessage]);
  }, [sessionId]);

  const handleSendMessage = async () => {
    // Remove the startingMessage if it exists
    setIsProcessing(true);
    if (message.trim()) {
      const chatData = { user_message: message };

      // Remove the startingMessage if it exists
      const updatedMessages = messages.filter(msg => msg.text !== startingMessage.text);
      setMessages([...updatedMessages, { sender: "User", text: message }]);
      setMessage("");

      try {
        const accessToken = await getAccessToken();
        const response = await fetch(`${api_url}/chat`, {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
          body: JSON.stringify(chatData),
        });

        if (!response.ok) {
          console.error("Error sending message:", response.statusText);
          setIsProcessing(false);
          return;
        }

        const reader = response.body?.getReader();
        const decoder = new TextDecoder("utf-8");
        let aiResponse = "";
        let aiResponseId = "";
        let aiLongResponseId = "";
        let aiTags: string[] = [];

        if (reader) {
          let buffer = "";

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            const chunk = decoder.decode(value, { stream: true });
            buffer += chunk;

            // Split buffer by newline to process each JSON object separately
            const parts = buffer.split("\n");

            for (let i = 0; i < parts.length - 1; i++) {
              if (parts[i].trim()) {
                const parsedChunk = JSON.parse(parts[i]);

                if (parsedChunk.token) {
                  aiResponse += parsedChunk.token;

                  setMessages((prevMessages) => {
                    const lastMessageIndex = prevMessages.findIndex(
                      (msg) => msg.sender === "AI" && !msg.id
                    );

                    if (lastMessageIndex !== -1) {
                      const updatedMessages = [...prevMessages];
                      updatedMessages[lastMessageIndex].text = aiResponse;
                      return updatedMessages;
                    }

                    return [...prevMessages, { sender: "AI", text: aiResponse }];
                  });
                } else if (parsedChunk.metadata) {
                  aiResponseId = parsedChunk.metadata.ai_response_id;
                  aiLongResponseId = parsedChunk.metadata.ai_response_summary_id;
                  aiTags = parsedChunk.metadata.recommended_tags;
                }
              }
            }

            // Keep the last part of the buffer in case it's an incomplete JSON object
            buffer = parts[parts.length - 1];
          }
        }

        // Final update with complete AI response, id, and tags
        setMessages((prevMessages) => {
          const lastMessageIndex = prevMessages.findIndex(
            (msg) => msg.sender === "AI" && !msg.id
          );

          if (lastMessageIndex !== -1) {
            const updatedMessages = [...prevMessages];
            updatedMessages[lastMessageIndex] = {
              ...updatedMessages[lastMessageIndex],
              text: aiResponse,
              id: aiResponseId,
              longTextId: aiLongResponseId,
              tags: aiTags,
            };
            return updatedMessages;
          }

          return [
            ...prevMessages,
            {
              sender: "AI",
              text: aiResponse,
              id: aiResponseId,
              longTextId: aiLongResponseId,
              tags: aiTags,
            },
          ];
        });
      } catch (error) {
        console.error("Error sending message:", error);
      }
      setIsProcessing(false);
    }
  };

  const handleAudioStart = () => {
    console.log("Recording started");
  };

  const handleAudioStop = () => {
    console.log("Recording stopped");
  };

  const handleAudioData = (text: string) => {
    setMessage((prevMessage) => prevMessage + " " + text);
  };

  const handleRetrieveMessage = async (messageId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await axios.get(`${api_url}/get_message`, {
        params: { message_id: messageId },
        headers: { Authorization: `Bearer ${accessToken}`},
      });
      const newMessage = response.data.message;
      setMessages(prevMessages => [...prevMessages, { id: messageId, text: newMessage, sender: "AI" }]);
    } catch (error) {
      console.error("Error retrieving message:", error);
    }
  };


  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box flexGrow={1} overflow="auto" p={2} mt={10}>
        <List>
          {messages.map((msg, index) => (
            <MessageBubble msg={msg} messageRetrievalHandler={handleRetrieveMessage} />
          ))}
        </List>
      </Box>
      <Box
        p={1}
        display="flex"
        alignItems="center"
        bgcolor="background.paper"
        sx={{ position: "sticky", bottom: 0 }}
      >
        <AudioStreamer
          onStart={handleAudioStart}
          onStop={handleAudioStop}
          onData={handleAudioData}
        />
        <TextField
          variant="outlined"
          placeholder="Type your message..."
          value={message}
          fullWidth
          multiline
          disabled={isProcessing} // Disable button while processing
          onChange={(e) => setMessage(e.target.value)}
          InputProps={{ style: { color: "#fff" } }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        {isProcessing ? (
          <CircularProgress style={{ marginLeft: "8px" }} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            style={{ marginLeft: "8px" }}
          >
            Send
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Chat;
