import React, { useState } from 'react';
import { Box, Button, RadioGroup, FormControlLabel, Radio, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { api_url } from "../../../config/api";
import { getAccessToken } from '../../../utils/auth';


interface SidebarProps {
    sessionId: string;
}

const Sidebar: React.FC<SidebarProps> = ({sessionId} : SidebarProps) => {
    const [rating, setRating] = useState<string>('Good');
    const [issueDescription, setIssueDescription] = useState<string>('');
    const [desiredResponse, setDesiredResponse] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);

    const handleSubmitFeedback = async () => {
        const accessToken = await getAccessToken();
        // console.log(accessToken);
        const headers = {
        Authorization: `Bearer ${accessToken}`,
        }
        const feedbackData = {
            rating,
            issue_description: issueDescription,
            desired_response: desiredResponse,
        };

        const response = await axios.post(`${api_url}/save_feedback`, feedbackData, { headers });
        if (response.status === 200) {
            setSubmitted(true);
            setIssueDescription('');
            setDesiredResponse('');
        } else {
            alert('Failed to submit feedback. Please try again.');
        }
    };

    return (
        <Box p={2}>
            <Typography variant="h6">Overall feedback on bot response</Typography>
            <RadioGroup
                value={rating}
                onChange={(e) => setRating(e.target.value)}
            >
                <FormControlLabel value="Good" control={<Radio />} label="Good" />
                <FormControlLabel value="Bad" control={<Radio />} label="Bad" />
            </RadioGroup>
            {/* <Typography variant="h6">Is the response too long?</Typography>
            <RadioGroup
                value={responseLength}
                onChange={(e) => setResponseLength(e.target.value)}
            >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup> */}
            <TextField
                variant="outlined"
                fullWidth
                multiline
                minRows={5}
                maxRows={25}
                placeholder="Describe the issue"
                value={issueDescription}
                onChange={(e) => setIssueDescription(e.target.value)}
                margin="normal"
                sx={{
                    '& .MuiInputBase-root': {
                        resize: 'vertical',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'flex-start',
                    },
                }}
            />
            <TextField
                variant="outlined"
                fullWidth
                multiline
                minRows={5}
                maxRows={25}
                placeholder="What would you have expected?"
                value={desiredResponse}
                onChange={(e) => setDesiredResponse(e.target.value)}
                margin="normal"
                sx={{
                    '& .MuiInputBase-root': {
                        resize: 'vertical',
                        overflow: 'auto',
                        display: 'flex',
                        alignItems: 'flex-start',
                    },
                }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitFeedback}
            >
                Submit Feedback
            </Button>
            {submitted && (
                <Typography variant="body2" color="success.main" style={{ marginTop: '8px' }}>
                    Thank you for your feedback!
                </Typography>
            )}
        </Box>
    );
};

export default Sidebar;
